
import { Options, Vue } from "vue-class-component";
import { DocumentList } from "@/types/Document";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import { IEmployeeDocument } from "@/types/EmployeeDocument";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import store from "@/store";
import axios from "axios";
import AppConsts from "@/shared/application/work-force";
import AuthStore from "@/store/auth-store";
import { notify } from "@/services/helpers";

@Options({
  name: "MyDocuments",
  components: {
    Breadcrumb,
    ConfirmationModal,
  },
})
export default class MyDocuments extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  documents: DocumentList[] = [];
  employeeDocuments: IEmployeeDocument[] = [];
  newEmployeeDocument: IEmployeeDocument = {
    documentId: 0,
    id: 0,
    employeeId: 0,
    documentName: "",
    fileName: "",
  };
  crumbs: any = ["Dashboard", "My Documents"];
  $refs!: {
    confirmDialogue: HTMLFormElement;
  };

  async initialize() {
    await this.workForceService
      .get<DocumentList[]>(`/documents/employee`, false)
      .then((response: any) => {
        this.documents = response.content;
      });

    await this.workForceService
      .get<IEmployeeDocument[]>(`/employeedocuments`, false)
      .then((response: any) => {
        this.employeeDocuments = response.content;
      });
  }

  getFileType(name: string) {
    const fileType = name.split(".")[1];
    if (fileType == "doc" || fileType == "docx") {
      return "doc";
    } else if (fileType == "pdf") {
      return "pdf";
    }
    return "file";
  }

  async created() {
    await this.initialize();
  }

  dialogObj: any;
  async signDocument(documentId: number) {
    this.newEmployeeDocument.documentId = documentId;

    const modal = await this.$refs.confirmDialogue.show({
      title: "Attention!",
      message:
        "Are you sure you want to sign. This means that you have fully read and understand the contents of this document.",
      okButton: "Ok, Proceed",
      cancelButton: "Cancel",
      theme: "primary",
      type: "confirmation",
    });

    if (modal) {
      await this.okClick();
    } else {
      return false;
    }
  }

  message = "";
  async okClick() {
    this.message = "";
    await this.workForceService
      .postOrPut<void>(
        "/employeedocuments",
        this.newEmployeeDocument as IEmployeeDocument,
        undefined
      )
      .then((response: any) => {
        if (!response.isError) {
          notify("Document signed successfully.", "Success", "success");
        } else {
          notify(response.errors, "Error", "danger");
        }
        this.initialize();
      });
  }

  async downloadDocument(id: number, fileName: string, docName: string) {
    axios
      .get(AppConsts.baseApiUrl + `/documents/download?id=${id}`, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${AuthStore.getToken()}` },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileExt = fileName.split(".").pop();
        link.setAttribute("download", docName + "." + fileExt);
        document.body.appendChild(link);
        link.click();
      });
  }
}
